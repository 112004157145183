import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material'

interface ThemeContextProps {
  themeMode: 'light' | 'dark'
  toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined)

interface CoreThemeProviderProps {
  children: ReactNode
}

export const CoreThemeProvider: React.FC<CoreThemeProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>(() => {
    if (typeof window !== 'undefined') {
      return (localStorage.getItem('themeMode') as 'light' | 'dark') || 'light'
    }
    return 'light'
  })

  useEffect(() => {
    const body = document.body
    if (themeMode === 'light') {
      body.className = 'light-theme'
    } else {
      body.className = 'dark-theme'
    }
    body.style.transition = 'background-color 0.3s, color 0.3s'
  }, [themeMode])

  const toggleTheme = () => {
    const newMode = themeMode === 'light' ? 'dark' : 'light'
    setThemeMode(newMode)
    if (typeof window !== 'undefined') {
      localStorage.setItem('themeMode', newMode)
    }
  }

  const theme = createTheme({
    palette: {
      mode: themeMode
    }
  })

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext)
  if (context === null) {
    throw new Error('useThemeContext must be used within a CoreThemeProvider')
  }
  return context
}

import React from 'react'

import { Link as GLink } from 'gatsby'
import { CssBaseline, Link, createTheme } from '@mui/material'

import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'

import { CustomNavigationClient } from './utils/NavigationClient'
import { isDevelopment, skipAuthentication } from './utils/config'
import { msalConfig } from './utils/auth'
import { LoginPage } from './components/LoginPage'
import { CoreThemeProvider } from './utils/themeContext'

const msalInstance = new PublicClientApplication(msalConfig)

const LinkBehavior = React.forwardRef<
HTMLAnchorElement,
any
>((props, ref) => {
  const { href, className, ...other } = props

  return (
    <Link
      to={href}
      component={GLink}
      className={className}
      ref={ref}
      underline='none'
      {...other}
    />
  )
})

const unileverTheme = createTheme({
  // [ ] @TODO: adjust theme colors https://mui.com/material-ui/customization/color/
  // palette: {
  //   primary: {
  //     light: '#f0f0f0',
  //     main: '#3f50b5',
  //     dark: '#002884',
  //     contrastText: '#fff'
  //   },
  //   secondary: {
  //     light: '#ff7961',
  //     main: '#f44336',
  //     dark: '#ba000d',
  //     contrastText: '#000'
  //   }
  // },
  typography: {
    fontFamily: [
      'Unilever Shilling',
      '-apple-system',
      'BlinkMacSystemFont',
      'Helvetica Neue',
      'sans-serif'
    ].join(' ,'),
    h1: {
      color: '#0400ff'
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    }
  }
})

interface WrapperProps {
  element: JSX.Element
}

const wrapWithProvider = ({ element }: WrapperProps): JSX.Element => {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient()
  msalInstance.setNavigationClient(navigationClient)
  msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      console.log(event)
      msalInstance.setActiveAccount(event.payload.account)
      window.location.reload()// refresh the page on first succesful login
    }
  })

  return (
    <CoreThemeProvider>
      <MsalProvider instance={msalInstance}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <AuthenticatedTemplate>
          {element}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {isDevelopment && skipAuthentication ? (element) : <LoginPage />}
        </UnauthenticatedTemplate>
      </MsalProvider>
    </CoreThemeProvider>
  )
}

export default wrapWithProvider
